<template>
  <b-card
    body-class="p-0"
    class="container-card-category m-0 mr-1"
    :style="getWidth()"
    @click="clickCard(casting_call)"
  > 
    <div class="position-img">
      <div class="div-top">
        <div class="d-flex align-items-center p-1">
          <b-avatar icon="shop" :src="img_logo" size="50" class="mr-1 z-index-2"></b-avatar>
          <div>
            <strong class="text-overflow-casting text-white z-index-2">{{casting_call.brand.name}}</strong>
            <p class="m-0 avenir-medium position-title z-index-2 text-white">{{$t('creator.type_of_service').find(response => response.value === casting_call.content_type).text}}</p>
          </div>
        </div>
      </div>
      <b-img 
       alt="image_cover_casting"
       @error="errorLoad"
       :src="main_image_content"
       class="img-casting-call"
      ></b-img>
    </div>
    <div class="div-bottom-card-casting-call"> 
      <span class="text-overflow">{{casting_call.title}} </span>
    </div>
  </b-card>
</template>
      
<script>
import { 
  BCard,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import { getAssetUrl } from '@/libs/utils/urls';

export default {
  name: 'cardCastingCall',
  components: {
    BCard,
    BImg,
    BAvatar,
  },
  props: {
    width: {
      type: String,
      default: '250px'
    },
    color: {
      type: String,
      default: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(160,0,255,1) 100%);'
    },
    casting_call: {
      type: Object,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      img_logo: this.casting_call.brand.logo_url ? this.casting_call.brand.logo_url : getAssetUrl(this.casting_call.brand.logo), 
      main_image_content: this.casting_call.cover_url ? this.casting_call.cover_url : getAssetUrl(this.casting_call.cover_image),
      timeouts: []
    }
  },
  methods: {
    errorLoad() {
      this.main_image_content = require("@/assets/images/dashboard/imgDefault.png");
    },
    clickCard(casting_call) {
      const url = `${window.location.origin}/view-casting-call/${casting_call.slug}`
      !this.redirect ? this.$router.push({name: 'view_casting_call', params: {slug: casting_call.slug}}) : window.open(url, '_blank');
    },
    getWidth() {
      return `width: ${this.width} !important;`
    },
  }
};
</script>

<style>
</style> 
<style scoped lang="scss">
.z-index-2 {
  z-index: 2;
}
.div-top {
  border-top-left-radius: 1em; 
  border-top-right-radius: 1em;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 80px;
  position: absolute;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.div-bottom-card-casting-call {
  border-bottom-left-radius: 1em; 
  border-bottom-right-radius: 1em;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 1em;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  left: 0;
  .text-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.position-title {
  position: absolute;
  z-index: 4;
}
.position-img{
  border-radius: 1em;
  height: 318px;
  width: 100%;
  overflow: hidden;
  .img-casting-call {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.text-overflow-casting {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position:absolute;
  top: 18px;
}
.mr-05 {
  margin-right: 0.5em;
}
.background-hover-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.5;
}
.container-card-category {
  cursor: pointer;
  border-radius: 1em;
  box-shadow: none !important;
  border: 1px solid #eee5e5 !important;
  background: transparent;
}
.container-card-category:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  transition: all 300ms;
}
.img-casting-call {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.text-hover-image-title {
  color: white;
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  font-size: 21px;
}
.template-footer {
  height: auto !important;
  padding: 7px;
  background: rgba(0,0,0,.75);
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
</style>